.app-content {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;

    &--portrait {
        min-width: 1080px;
        max-width: 1080px;
        min-height: 1920px;
        max-height: 1920px;
    }

    &--landscape {
        min-width: 1920px;
        max-width: 1920px;
        min-height: 1080px;
        max-height: 1080px;
    }

    &__label {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid;
        border-top: 0;
        padding: 5px;
        font-weight: 600;
        font-size: 19px;
    }
}


.react-tabs {

    &__tab-panel {
        position: absolute;
        top: 20%;
        width: 100%;
        height: 100%;
        max-height: 1335px;

        &--selected {
            z-index: 1;
        }
    }
}

.slick-slide.slick-active.slick-current {
    z-index: 45;
}
