%boathouse-list-item {
    padding: 0 15px;

    .menu-item__header>* {
        position: relative;

        &:after {
            content: "*";
            position: absolute;
            top: 50%;
            left: -15px;
            height: auto;
            line-height: .5;
        }
    }
}

$themeColor: #007CB0;


%boathouse-styles {
    @include keyframes(fill-bar-boathouse) {
        0%   { width: 0%; background: #ccc }
        5%   { background: $themeColor; }
        100% { width: 100%; }
    }
    @include keyframes(fade-bar-boathouse) {
        0%   { width: 100%; background: $themeColor; }
        5%   { background: #ccc }
        100% { width: 0%; }
    }
    @include keyframes(fill-background-boathouse) {
        0%   { background: #ccc }
        5%   { background: $themeColor; }
        100% { background: $themeColor; }
    }
    @include keyframes(fade-background-boathouse) {
        0%   { background: $themeColor; }
        5% { background: #ccc }
        100% { background: #ccc }
    }

    .app-content__label,
    .time-part__label,
    .time-part__time {
        display: none;
    }

    .slick-slider.special-stations {
        display: none;
    }

    .station {
        width: auto;
        font-size: 36px;
        line-height: 60px;

        &__item {
            margin: 0;
        }

        &--substation {
            > .station__header {
                line-height: 30px;
                border-bottom: 1px solid;
                text-transform: uppercase;
            }
        }

        &--not-substation {
            width: 100%;
            flex-basis: auto;

            > .station__header {
                @extend %bg-border-header;
                font-size: 50px;
                line-height: 80px;

                strong {
                    font-weight: 500;
                }

                button {
                    display: none;
                }
            }
        }
        &--substation {

            > .station__header {
                strong {
                    font-size: 27px;
                }
            }

            .station__item {
                @extend %boathouse-list-item;
            }
        }

        &__sub-stations {
            display: block;
        }
    }

    .menu-item {
        display: inline-block;


        &__icons {
            display: none;
        }
        &__description {
            display: block;
            font-size: 30px;
            line-height: 40px;
        }


        &--has-description {
            display: block;
            margin-bottom: 15px;

            > .menu-item__header {
                font-weight: 600;
            }
        }

        &:not(.menu-item--has-description) {
            @extend %boathouse-list-item;
        }
    }

    .food-legend {
        bottom: 6.5%;
        display: none;
    }



    .slick {

        &-slider {
            // &.tier-1-stations {
            //     display: none;
            // }
        }

        &-slide {
            height: 975px;
            max-height: 975px;
        }

        // This overwrites default slick
        &-dots {
            display: flex !important;
            justify-content: center;

            li {
                margin: 0;
                width: 33%;
                height: auto;

                button:before {
                    content: "";
                    z-index: 2;
                    opacity: 1;
                    width: 5px;
                    background: #fff;
                    color: transparent;
                    transform: translate(-3px, 0px) skewX(-22deg);
                }

                &:first-child {
                    button:before {
                        background: transparent;
                    }
                    .slick-dot-bg {
                        border-top-left-radius: 50px;
                        border-bottom-left-radius: 50px;
                    }
                }
                &:last-child {
                    .slick-dot-bg {
                        border-top-right-radius: 50px;
                        border-bottom-right-radius: 50px;
                    }
                }
            }

        }

        &-dot {
            color: #fff;
            width: 100%;
            height: auto;
            padding: 5px 0px;
            font-size: 20px;
            line-height: initial;
            position: relative;

            .slick-dot-value {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: -50%;
                left: 50%;
                padding: 5px;
                width: 40px;
                height: 40px;
                border: 4px solid;
                border-radius: 50%;
                transform: translate(-50%, 0%);
                background-color: #ccc;
                @include prefix( (
                    animation-name: fade-background-boathouse,
                    animation-duration: 10s,
                ) );
            }

            .slick-dot-bg {
                height: 10px;
                background-color: #ccc;
                overflow: hidden;

                &:after {
                    content: "";
                    display: block;
                    height: 100%;
                    width: 0%;
                    background-color: #ccc;
                    @include prefix( (
                        animation-name: fade-bar-boathouse,
                        animation-duration: 10s,
                    ) );
                }
            }
        }


        &-dots .slick-active {
            .slick-dot-value {
                background-color: $themeColor;
                @include prefix((
                    animation-name: fill-background-boathouse,
                    animation-duration: 10s,
                ));
            }
            .slick-dot-bg {
                &:after {
                    width: 100%;
                    background-color: $themeColor;

                    @include prefix((
                        animation-name: fill-bar-boathouse,
                        // animation-duration: 10s,
                    ));
                }
            }
        }
    }

}

.app-content {
    &--22420 {
        @extend %boathouse-styles;
        overflow: hidden;

        // background-image: url('../../../img/cafe/Sketch.jpg');
    }

}
