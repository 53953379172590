.time-part {
    @extend %header-inherit;

    &__label {
        @extend %bg-border-header;
        font-family: $font-stack-Satisfy;
        text-transform: capitalize;
    }

    &__time {
        font-size: 22px;
        text-align: center;
        margin-bottom: 50px;
    }

    &__station {
        display: flex;
        flex-wrap: wrap;
    }
}
