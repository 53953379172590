


@mixin general-styles( $themeName, $color, $slide_fade_speed ) {



    @include keyframes(fill-bar-#{$themeName}) {
        0%   { width: 0%; background: #ccc }
        5%   { background: $color; }
        100% { width: 100%; }
    }
    @include keyframes(fade-bar-#{$themeName}) {
        0%   { width: 100%; background: $color; }
        5%   { background: #ccc }
        100% { width: 0%; }
    }
    @include keyframes(fill-background-#{$themeName}) {
        0%   { background: #ccc }
        5%   { background: $color; }
        100% { background: $color; }
    }
    @include keyframes(fade-background-#{$themeName}) {
        0%   { background: $color; }
        5% { background: #ccc }
        100% { background: #ccc }
    }


    overflow: hidden;
    background-image: url('../../../img/order_up.png');

    .app-content {
        &__label{
            display: none;
        }

    }

    &.app-content {
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
        }
        &--breakfast:after {
            background-image: url('../../../img/food/breakfast.png');
        }

        &--lunch:after {
            background-image: url('../../../img/food/lunch.png');
        }

        &--dinner:after {
            background-image: url('../../../img/food/dinner.png');
        }
    }

    .time-part {
        &__station {
            padding: 0 20px;
        }

        .slick {

            &-slider {
                &.tier-1-stations {
                    display: none;
                }
            }

            &-slide {
                height: 975px;
                max-height: 975px;
            }

            // This overwrites default slick
            &-dots {
                display: flex !important;
                justify-content: center;

                li {
                    margin: 0;
                    width: 33%;
                    height: auto;

                    button:before {
                        content: "";
                        z-index: 2;
                        opacity: 1;
                        width: 5px;
                        background: #fff;
                        color: transparent;
                        transform: translate(-3px, 0px) skewX(-22deg);
                    }

                    &:first-child {
                        button:before {
                            background: transparent;
                        }
                        .slick-dot-bg {
                            border-top-left-radius: 50px;
                            border-bottom-left-radius: 50px;
                        }
                    }
                    &:last-child {
                        .slick-dot-bg {
                            border-top-right-radius: 50px;
                            border-bottom-right-radius: 50px;
                        }
                    }
                }

            }

            &-dot {
                color: #fff;
                width: 100%;
                height: auto;
                padding: 5px 0px;
                font-size: 20px;
                line-height: initial;
                position: relative;

                .slick-dot-value {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: -50%;
                    left: 50%;
                    padding: 5px;
                    width: 40px;
                    height: 40px;
                    border: 4px solid;
                    border-radius: 50%;
                    transform: translate(-50%, 0%);
                    background-color: #ccc;
                    @include prefix( (
                        animation-name: fade-background-#{$themeName},
                        animation-duration: 10s,
                    ) );
                }
                .slick-dot-bg {
                    height: 10px;
                    background-color: #ccc;
                    overflow: hidden;

                    &:after {
                        content: "";
                        display: block;
                        height: 100%;
                        width: 0%;
                        background-color: #ccc;
                        @include prefix((
                            animation-name: fade-bar-#{$themeName},
                            animation-duration: 10s,
                        ));
                    }
                }
            }


            &-dots .slick-active {
                .slick-dot-value {
                    background-color: $color;
                    @include prefix((
                        animation-name: fill-background-#{$themeName},
                        animation-duration: 10s,
                    ));
                }
                .slick-dot-bg {
                    &:after {
                        width: 100%;
                        background-color: $color;
                        @include prefix((
                            animation-name: fill-bar-#{$themeName},
                            animation-duration: 10s,
                        ));
                    }
                }
            }

        }
    }

    .station {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;

        &__header {
            margin-bottom: 12px;
        }

        &--substation {
            display: none;
        }
    }

    .menu-item {
        line-height: 1.4;
        margin-bottom: 7px;
    }

    .food-legend {
        bottom: 170px;
    }

}

$themeColor: #000;
$slideFadeSpeed: ".85s";

.app-content {
    &--551 { // Hive
        $themeColor: #ffcc00;
        @include general-styles( 551, $themeColor, $slideFadeSpeed);

        background-image: url('../../../img/cafe/Cafe-at-the-Hive.jpg');
    }

    &--553 { // Ration
        $themeColor: #ff7e00;
        @include general-styles( 553, $themeColor, $slideFadeSpeed);

        background-image: url('../../../img/cafe/Ration.jpg');
    }

    &--573 { // The Hub
        $themeColor: #fc743a;

        @include general-styles( 573, $themeColor, $slideFadeSpeed);
        background-image: url('../../../img/cafe/The-Hub.jpg');
    }

    &--743 { // Byte
        $themeColor: #fea247;

        @include general-styles( 743, $themeColor, $slideFadeSpeed);
        background-image: url('../../../img/cafe/Byte-Cafe.jpg');
    }
}
