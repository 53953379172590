.playlist {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000;
    /*background-image: url('../../../img/order_up.png');*/
    position: relative;
    z-index: 10;

    &__slider {
        background: #000000;
        width: 100%;
    }

    &__slide {
        font-size: 71px;
    }

    .slick {

        &-track {
            display: flex;
            align-items: center;
        }

        &-slide {
            &.slick-active.slick-current {
                    z-index: 100;
            }
        }
    }
}


ul.react-tabs__tab-list {
    display: none;
}

.menu__header {
    display: none;
}
