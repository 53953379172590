.food-legend {
    position: absolute;
    width: 94%;
    width: calc(100% - 60px);
    bottom: 10.5%;
    margin: 0 30px;
    border: 1px solid;
    padding: 8px 10px;
    display: grid;
    grid-template-columns: auto auto auto;

    // Item info
    &__item {
        display: flex;
        padding: 5px 10px;
        font-size: 20px;
        text-transform: uppercase;

        > * {
            padding: 0 4px;
        }
    }
}
