%sketch-styles {
    background-image: url('../../../img/CafeHive_Lunch_new.jpg');

    .app-content__label,
    .time-part__label,
    .time-part__time {
        display: none;
    }

    .slick-slider.special-stations {
        display: none;
    }

    .station {
        font-size: 25px;

        &__items {
            text-align: center;
        }
        &__item {
            margin: 12px 0;
        }

        &--not-substation {
            width: 100%;
            flex-basis: auto;

            > .station__header {
                @extend %bg-border-header;

                strong {
                    font-weight: 500;
                }

                button {
                    display: none;
                }
            }
        }
        &--substation {
            -ms-flex: 0 0 50%;
            flex: 0 0 50%;
            max-width: 50%;
            text-align: center;

            > .station__header {
                strong {
                    font-size: 27px;
                }
            }

            .station__item {
                display: block;
            }
        }

        &__sub-stations {
            justify-content: center;
        }
    }

    .food-legend {
        bottom: 6.5%;
    }

}

.app-content {
    &--2242 {
        @extend %sketch-styles;

        background-image: url('../../../img/cafe/Sketch.jpg');
    }

}
