.station {
    width: 50%;
    padding: 15px;
    font-size: 27px;

    &__header {
        text-transform: uppercase;

        button {
            display: none;
            font-size: 15px;
            padding: 2px 6px;
            background: #eaeaea;
            border: 2px solid rgba(0, 0, 0, 0.2);
        }
    }

    &__item {
        display: list-item;
        margin-left: 25px;
    }

}
