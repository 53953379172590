
/** Mixin to prefix several properties at once
  @author Hugo Giraudel
  @param {Map} $declarations - Declarations to prefix
  @param {List} $prefixes (()) - List of prefixes to print

  @example
    @include prefix((
      column-count: 3,
      column-gap: 1.5em,
      column-rule: 2px solid hotpink
    ), webkit moz);
 */
@mixin prefix($declarations, $prefixes: (webkit moz o)) {
  @each $property, $value in $declarations {
    @each $prefix in $prefixes {
      #{'-' + $prefix + '-' + $property}: $value;
    }

    // Output standard non-prefixed declaration
    #{$property}: $value;
  }
}

/** Mixin to prefix several properties at once
  @author Hugo Giraudel
  @param   {String}  $animationName - name of animation
  @content {CONTENT} $@content      - content provided within braces ({})

  @example
    @include keyframes(move-the-object) {
      0%   { left: 100px; }
      100% { left: 200px; }
    }
 */
@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}
