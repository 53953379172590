.menu-item {
    &__header {
        > * {
            display: inline;
        }
    }

    &__icon {
        display: inline;
        padding: 0 5px;

        img {
            display: inline-block;
        }
    }

    &__option_header {
        font-size: 20px;
        font-weight: 600;
    }

    &__option_description {
        font-size: 20px;
        font-style: oblique;
    }

    &__description {
        display: none;
    }
}
