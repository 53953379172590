%header-inherit {

    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h1, .h6 {
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
        text-transform: inherit;
    }
}


%bg-border-header {
    font-size: 55px;
    margin-top: 80px;
    margin-bottom: -50px;
    background: #fff;
    text-align: center;
    border-top: 1px solid #000;

    > * {
        display: inline-block;
        transform: translateY(-50%);
        background: inherit;
        padding: 0 8px;
    }
}
