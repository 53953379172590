// get layout element
@import "~video-react/styles/scss/video-react";
@import './gobal-styles/vars';
@import './gobal-styles/mixins';
@import './gobal-styles/extends';
@import './gobal-styles/tag_base.scss';
@import './gobal-styles/accessibility';

// Third Party
@import "../../node_modules/slick-carousel/slick/slick.css";
@import "../../node_modules/slick-carousel/slick/slick-theme.css";


// components
@import './gobal-styles/components/page';
@import './gobal-styles/components/food-legend';
@import './gobal-styles/components/station';
@import './gobal-styles/components/time-part';
@import './gobal-styles/components/playlist';
@import './gobal-styles/components/menu-item';

// Themes
@import './themes/default/themes';
@import './themes/sketch/themes';
@import './themes/boathouse/themes';
