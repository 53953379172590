body {
    font-family: $font-stack-default;
    background-color: #fafafa;
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
}

button {
  padding: 0;
  border: 0;
}
